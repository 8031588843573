  a:hover{
    text-decoration: none;
  }
  a:active {
    text-decoration: none;
  }
  
  .button {
    //display: flex;
    padding: 5px 10px;
    align-items: center;
    position: relative;
    text-align: center;
    justify-content: center;
    width: auto;
    //height: 54px;
    border: 3px solid var(--blue-aeroday);
    border-radius: 3px;
    font-size: 1rem; //20px;
    font-weight: bolder;
    cursor: pointer;
    
    > .text {
      position: relative;
      color: transparent;
      background-image: linear-gradient(
        90deg,
        var(--red-aeroday),
        var(--red-aeroday) 50%,
        var(--blue-aeroday) 50%,
        var(--blue-aeroday) 100%
      );
      background-size: 300%;
      background-position: 100% 0;
      background-clip: text;
      transition: background-position 300ms;
      width: 180px;
    }
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #272d31;//slider color
      transform-origin: 100% 0;
      transform: scale3d(0, 1, 1);
      transition: transform 300ms;
    }
    
    &:hover {
      .text {
        background-position: 0 0;
      }
  
      &::before {
        transform-origin: 0 0;
        transform: scale3d(1, 1, 1);
      }
    }
  }
