@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800&display=swap");
:root {
	--card-width: 200px;
	--card-height: 300px;

	--card-transition-duration: 600ms;
	--card-transition-easing: ease;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.slide_wrapper {
	width: 100%;
	height: 90vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.5)!important;
	overflow: hidden;
	box-shadow: 0px -15px 10px 1px #0f0f0f81;
	
}

button {
	border: none;
	background: none;
	cursor: pointer;
	&:focus {
		outline: none;
		border: none;
	}
}

.app {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	&__bg {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: -5;
		filter: blur(8px);
		pointer-events: none;
		user-select: none;
		overflow: hidden;
		

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: #000;
			z-index: 1;
			opacity: 0.2;
		}

		&__image {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%) translateX(var(--image-translate-offset, 0));
			width: 180%;
			height: 180%;
			transition: transform 1000ms ease, opacity 1000ms ease;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;

			}
			&.current--image {
				opacity: .4;
				--image-translate-offset: 0;
			}
			&.previous--image,
			&.next--image {
				opacity: 0;
			}
			&.previous--image {
				--image-translate-offset: -25%;
			}
			&.next--image {
				--image-translate-offset: 25%;
			}
		}
	}
}

.cardList {
	position: absolute;
	width: calc(3 * var(--card-width));
	height: auto;

	&__btn {
		--btn-size: 60px;
		width: var(--btn-size);
		height: var(--btn-size);
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 100;

		&.btn--left {
			left: -5%;
		}
		&.btn--right {
			right: -5%;
		}

		.icon {
			width: 100%;
			height: 100%;
			svg {
				width: 100%;
				height: 100%;
			}
		}
	}

	.cards__wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		perspective: 1000px;
	}
}

.card {
	--card-translateY-offset: 10%;

	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%) translateX(var(--card-translateX-offset))
		translateY(var(--card-translateY-offset)) rotateY(var(--card-rotation-offset))
		scale(var(--card-scale-offset));
	display: inline-block;
	width: var(--card-width);
	height: var(--card-height);
	transition: transform var(--card-transition-duration)
		var(--card-transition-easing);
	user-select: none;

	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #000;
		z-index: 1;
		transition: opacity var(--card-transition-duration)
			var(--card-transition-easing);
		opacity: calc(1 - var(--opacity));
	}

	&__image {
		position: relative;
		width: 100%;
		height: 100%;
		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&.current--card {
		--current-card-rotation-offset: 0;
		--card-translateX-offset: 0;
		--card-rotation-offset: var(--current-card-rotation-offset);
		--card-scale-offset: 1.2;
		--opacity: 0.8;
	}

	&.previous--card {
		--card-translateX-offset: calc(-1 * var(--card-width) * 1.1);
		--card-rotation-offset: 25deg;
	}

	&.next--card {
		--card-translateX-offset: calc(var(--card-width) * 1.1);
		--card-rotation-offset: -25deg;
	}

	&.previous--card,
	&.next--card {
		--card-scale-offset: 0.9;
		--opacity: 0.4;
	}
}

.infoList {
	position: absolute;
	width: calc(3 * var(--card-width));
	height: var(--card-height);
	pointer-events: none;

	.info__wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
	perspective: 1000px;
	transform-style: preserve-3d;
	}
}

.info {
	margin-bottom: calc(var(--card-height) / 8);
	margin-left: calc(var(--card-width) / 1.5);
	transform: translateZ(2rem);
	transition: transform var(--card-transition-duration)
		var(--card-transition-easing);
	.text {
		position: relative;
		font-family: "Montserrat";
		font-size: calc(var(--card-width) * var(--text-size-offset, 0.2));
		white-space: nowrap;
		color: #fff;
		width: fit-content;
	}

	.name,
	.location {
		text-transform: uppercase;
	}

	.location {
		font-weight: 800;
	}

	.location {
		--mg-left: 40px;
		--text-size-offset: 0.12;
		font-weight: 600;
		margin-left: var(--mg-left);
		margin-bottom: calc(var(--mg-left) / 2);
		padding-bottom: 0.8rem;
		&::before,
		&::after {
			content: "";
			position: absolute;
			background: #fff;
			left: 0%;
			transform: translate(calc(-1 * var(--mg-left)), -50%);
		}
		&::before {
			top: 50%;
			width: 20px;
			height: 5px;
		}
		&::after {
			bottom: 0;
			width: 60px;
			height: 2px;
		}
	}

	.description {
		--text-size-offset: 0.065;
		font-weight: 500;
	}

	&.current--info {
		opacity: 1;
		display: block;
	}
	&.previous--info,
	&.next--info {
		opacity: 0;
		display: none;
	}
}

@media only screen and (min-width: 800px) {
	:root {
		--card-width: 250px;
		--card-height: 400px;
	}
}

.support {
	position: absolute;
	right: 10px;
	bottom: 10px;
	padding: 10px;
	display: flex;
	a {
		margin: 0 10px;
		color: #fff;
		font-size: 1.8rem;
		backface-visibility: hidden;
		transition: all 150ms ease;
		&:hover {
			transform: scale(1.1);
		}
	}
}
