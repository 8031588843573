.tenth-small{
    position: fixed;
    right: 0;
    top: 19%;
    img{
        width: 75%;
    }
}
.tenth-hover{
    position: fixed;
    transition: 0.2s ease-in;
    opacity: 0;
    bottom: 5%;
    width: 100%;
    display: flex;
    justify-content: center;
    img{
        width: 90%;
        max-width: 700px;
    }
}

.tenth-small:hover + .tenth-hover{
    opacity: 1;
}

@media screen and (max-width: 900px){
    .tenth-small, .tenth-hover{
        display: none;
    }
}