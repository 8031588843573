


.dropdown{
  width: 100%;
  background-color: rgba(217, 82, 82, 0.5);
  color: var(--white-aeroday);
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 5px;
  position: relative;
  transition: all 0.5s;
  .droptitle{
    width: fit-content;
    margin: auto;
  }

  .arrow{
    width: 18px;
    position: absolute;
    right: 5px;
    top: 10px;
    opacity: 0.8;
    &.rotate{
      transform: rotate(90deg);
    }
  }

  .dropdown-content{
    width: 100%;
    height: 0;
    border-radius: 10px;
    background-color: #01292e8a;
    overflow-y: scroll;
    transition: all 0.5s;

    &.open{
      height: 80vw;
    }
  }
}

@media screen and (max-width: 340px) {
  .dropdown .droptitle{
    font-size: xx-small;
  }
}