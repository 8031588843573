.formulaire{
  form{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    .verticals{
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
    }
  }
}
