


.input{
  margin: 20px;

  input, textarea{
    padding: 10px 15px;
    background-color: #519da629;
    border-radius: 10px;
    border: 2px solid var(--blue-aeroday);
    &:focus-visible{
      outline: none;
      box-shadow: 0 0 21px 3px rgba(81, 157, 166, 0.5);
    }
    textarea{
      width: 100%;
    }
  }
}
