//@media screen and (min-width: 900px) and (max-width: 1250px) {
//  .ac-box {
//    min-height: 500px;
//    .ACimage {
//      min-height: 500px;
//    }
//    .title.rotate {
//      width: 500px;
//      transform: translate(-38%, calc(50% - 250px)) rotate(90deg);
//    }
//  }
//}

.ac-box {
  position: relative;
  width: 40vw;
  height: 40vw;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 0 30px 0 black;
  margin: 0 2.5vw;
  display: inline-block;
  cursor: pointer;
  transition: all 0.5s;

  .ACimage {
    position: absolute;
    top: 50%;
    left: 50%;
    right: -25%;
    height: 40vw;
    width: auto;
    filter: grayscale(0.8) blur(0);
    transition: all 0.5s;
    transform: translate(-50%, -50%);
  }

  &:hover {
    .ACimage {
      filter: unset;
      transform: scale(1.05) translate(-50%, -50%);

      & + .title {
        display: flex;
      }
    }
  }

  .title {
    width: 40vw;
    height: 10vw;
    text-align: center;
    color: var(--white-aeroday);
    font-weight: bold;
    position: absolute;
    bottom: 0;
    display: none;
    transition: all 0.5s;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 20px 0;
    justify-content: center;
    align-items: center;

    &.rotate {
      transform: translate(-39%, calc(50% - 20vw)) rotate(90deg);
    }

    h1 {
      font-family: 'Assistant', 'nexa_boldregular', 'sans-serif';
      margin: 0;
      font-weight: 700;
      letter-spacing: 5px;
    }
  }

  .dropdown-container {

    opacity: 0;
    width: 100%;
    /* margin: 10px 20px; */
    position: absolute;
    padding: 15px;
    right: 0;
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    z-index: -1;
  }
}

.ac-container {
  position: relative;
}

.ac-box + .ac-show {
  position: absolute;
  top: 6px;
  display: flex;
  flex-direction: row-reverse;
  transition: all 0.5s;
  &.challenges {
    flex-direction: row;
    right: -100vw;
    &.translated{
      transform: translateX(-97.5vw);
    }
  }

  &.axes {
    left: -100vw;
    &.translated{
      transform: translateX(100vw);
    }
  }

  .ac-navigation {
    transform: translateY(-5vw);

    svg {
      height: 50vw;
    }
  }

  .AC {
    width: fit-content;
  }
}

@media screen and (min-width: 1301px) {
  .ac-box + .ac-show {
    .ac-navigation {
      svg {
        height: 45vw;
      }
    }

    &.axes.translated {
      transform: translateX(106vw);
    }
    &.challenges.translated {
      transform: translateX(-106vw);
    }
  }
}

@media screen and (min-width: 900px) and (max-width: 1250px) {
  .ac-box {
    height: 45vw !important;

    .ACimage {
      height: 45vw;
    }

    .title {
      width: 45vw;

      &.rotate {
        transform: translate(-40%, calc(50% - 22.5vw)) rotate(90deg);
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .ac-box {
    height: 80vw;
    max-height: 300px;

    .title {
      width: 50vw;
      display: flex;
      left: -10%;

      &.rotate{
        transform: translate(-25%, calc(50% - 150px)) rotate(90deg);
      }
      h1 {
        letter-spacing: normal;
      }
    }

    .ACimage {
      height: 50vw;
      filter: unset;
      transform: scale(1.05) translate(-50%, -50%);

      &.expanded {
        right: 0;
        height: auto;
        width: 80vw;
        transition: none;
      }
    }

    &.open {
      //max-height: unset;
      height: 150vw !important;
    }
  }
  .ac-box + .ac-show {
    display: none;
    .ac-navigation{
      display: none;
    }
  }

}

@media screen and (max-width: 700px) {
  .ac-box .title h1 {
    font-size: initial;
  }
}