
.main-contact {
  margin-top: clamp(80px, 20vh, 120px);
  height: 80vh;
  display: flex;
  align-items: center;
  //justify-content: center;
  overflow: hidden;
}
.name-email {
  display: flex;
  width: 25%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-inside {
  height: 3rem;
  background-color: #32444a;
  text-align: center;
  width: 100%;
}

.name {
  border-radius: 9999em;
  width: 80%;
}

.email {
  margin-top: 3rem;
  width: 80%;
  border-radius: 9999em;
}

.send-btn {
  margin-top: 4rem;
}

.msg {
  border-radius: 20px;
  width: 50%;
  height: 25rem;
  margin-left: 2rem;
}

.msg-inside {
  //padding: 40px;
  width: 100%;
  height: 100%;
  resize: none;
  //width: 30rem;
}

.plane-animated {
  width: 80%;
  margin-bottom: 20px;
}
.msg-plane {
  display: flex;
  //flex-direction: column;
}

.plane {
  margin-left: 4rem;
  display: flex;
  align-items: center;
}

.plane-inside {
  width: 100%;
}

@media (max-width: 900px) {
  .main-contact {
    //height: 160vh;
    flex-direction: column;
    height: fit-content;
  }

  .msg-plane {
    flex-direction: column;
  }
  .name-email {
    width: 80%;
    flex-direction: column;
  }
  .send-btn {
    margin-top: 2rem;
  }
  .msg{
    margin-top: 1rem;
    margin-left: 0;
    width: 80%;
    height: 15rem;
  }
  .msg-plane {
    align-items: center;
    justify-content: center;
  }
  .plane {
    margin-left: 0;
    margin-top: 2rem;
  }
}
